<template>
    <div class="TopIndex">
        <div class="TopNav" :class="{'TopNavBgBlack': state.isScrolled}">
            <el-row>
                <el-col :span="8">
                    <div class="logo">
                        <span>祈年安安</span>
                    </div>
                </el-col>
                <el-col :span="11">
                    <div class="topMenu">
                        <div style="padding: unset">
                            <el-input
                              v-model="state.search"
                              placeholder="搜索"
                              suffix-icon="Search"
                              size="small"
                              @input="Search"
                            />
                        </div>
                        <div @click="goHome">
                            <span>首页</span>
                        </div>
                        <div @click="goClassify">
                            <span>归档</span>
                        </div>
<!--                        <div @click="goTimeLine">-->
<!--                            <span>时间线</span>-->
<!--                        </div>-->
<!--                        <div>-->
<!--                            <span>留言</span>-->
<!--                        </div>-->
                        <div @click="goAbout">
                            <span>关于</span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="5">
                    <div class="avatar">
                        <el-avatar :size="45" :src="state.circleUrl" />
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
import {onMounted, onUnmounted, reactive} from 'vue';
import {useRouter} from "vue-router";
import store from "@/store";
export default {
    setup() {
        const state = reactive({
            circleUrl:
                'https://q1.qlogo.cn/g?b=qq&nk=2715355163&s=100',
            squareUrl:
                'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
            search: '',
            isScrolled: false
        })

        const handleScroll = () => {
          state.isScrolled = window.scrollY > 384;
        };

        onMounted(() => {
          window.addEventListener('scroll', handleScroll);
        });

        onUnmounted(() => {
          window.removeEventListener('scroll', handleScroll);
        });

        const router = useRouter();

        const goHome = () => router.replace('/');
        const goClassify = () => router.push({name: 'classify'})
        const goTimeLine = () => router.push({name: 'timeline'})
        const goAbout = () => router.push({name: 'about'})

        const Search = () => {
            store.commit('setSearch', state.search)
            goHome()
        }

        return {
            state,
            handleScroll,
            Search,
            goHome,
            goClassify,
            goTimeLine,
            goAbout
        }
    }
}
</script>

<style lang="scss" scoped>
.TopIndex {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 999;

    .TopNav {
        width: 100%;
        /*// height: 50px;*/
        /*// line-height: 50px;*/
        //background-color: rgba(17, 17, 17, 0.75);
        box-shadow: 0 3px 7px 0 rgba(0,0,0,0.35);
        color: black;

        
    }
    .TopNav.TopNavBgBlack {
        //width: 100%;
        /*// height: 50px;*/
        /*// line-height: 50px;*/
        background-color: rgba(17, 17, 17, 0.75);
        //box-shadow: 0 3px 7px 0 rgba(0,0,0,0.35);
        color: white;
    }


    .logo {
            font-size: 30px;
            margin: 10px 20px;
            font-family: 'HYQingYunW',serif;
            font-weight: 550;
            cursor: pointer;

        }

        .topMenu {
            display: flex;
            justify-content: end;
            font-size: 20px;
            font-family: HYQingYunW,serif;

            div {
                height: 100%;
                // margin: 0 20px;
                padding: 16px;
            }

            div:hover {
                // background-color: rgb(224, 237, 240);
                text-shadow: 0 0 10px #69e0ff, 0 0 20px #69e0ff, 0 0 40px #69e0ff;
                font-weight: 600;
                cursor: pointer;
            }
        }

        .avatar {
            text-align: end;
            padding-top: 5px;
            margin-right: 10px;

        }

    ::v-deep .el-avatar>img {
            cursor: pointer;
        }
}
</style>