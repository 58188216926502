<template>
    <div>
        <el-affix>
          <TopView></TopView>
        </el-affix>
        <TopBg></TopBg>
        <div class="container" v-loading="Loading" element-loading-text="Loading....">
            <el-row justify="center">
                <el-col :span="14">
                    <div class="container_base container_main" >
                        <div class="container_main_top">
                            <el-breadcrumb separator="/">
                                <el-breadcrumb-item style="cursor: pointer" @click="goBack">返回</el-breadcrumb-item>
                                <el-breadcrumb-item>其他</el-breadcrumb-item>
                            </el-breadcrumb>
                            <div class="container_main_top_title">{{detailinfo.title}}</div>
                        </div>
                        <el-divider />
                        <div class="container_main_content" id="content" v-html="detailinfo.content"></div>
                    </div>
                    <!--评论区-->
                    <div class="container_base container_main ">
                        <div class="container_main_content">
                            <CommentView :article_id="detailinfo.id" ></CommentView>
                            <el-divider content-position="center">评论区</el-divider>
                            <CommentItem v-if="detailinfo.comments" :article_id="detailinfo.id" :article_comment="detailinfo.comments" ></CommentItem>
                        </div>
                    </div>
                </el-col>
                <el-col :span="5">
                    <div class="container_right">
                        <div class="container_base container_right_userInfo">
                            <div class="container_right_userInfo_bg"></div>
                            <div class="container_right_userInfo_head">
                                <img src="https://q1.qlogo.cn/g?b=qq&nk=2715355163&s=100" alt="#"/>
                            </div>
                            <div class="container_right_userInfo_nikeName">祈年安安</div>
                            <div style="padding: 0 15%;">
                                <el-divider />
                            </div>
                            <div class="container_right_userInfo_signature">且视他人之疑目如盏盏鬼火，大胆去走你的路</div>
                        </div>
                        <el-affix position="top" :offset="70">
                            <div class="container_base container_right_directory">
                                <div class="container_right_directory_top">
                                    <span>内容锚点</span>
                                    <el-divider border-style="dashed"/>
                                </div>
                                <ul v-if="tocItems" id="directory">
                                    <li v-for="item in tocItems" :key="item.id">
                                        <span @click="menuJump(item.id)">{{item.text}}</span>
                                    </li>
                                </ul>
                            </div>
                        </el-affix>

                    </div>
                </el-col>
            </el-row>

        </div>
        <el-backtop :right="100" :bottom="100" />
        <FooterView></FooterView>
    </div>
</template>

<script>
    import TopView from './TopView.vue';
    import TopBg from "@/components/TopBg";
    import {reactive, onMounted, onBeforeMount, ref,} from "vue";
    import {detailInfo} from "@/api/base";
    import FooterView from "@/views/FooterView.vue";
    import CommentView from "@/components/CommentView.vue";
    import CommentItem from "@/components/CommentItem.vue";
    import {generateToc} from "@/utils/utils";
    export default {
        name: "DetailView",
        components: {
            CommentItem,
            TopView,
            TopBg,
            FooterView,
            CommentView
        },
        setup() {
            const detailinfo = reactive({
                // comments: [] //初始化评论数组
            });
            const tocItems = ref([]);
            const Loading = ref(true)

            const menuJump = (id) => {
                let aTag = document.getElementById(id)
                window.scroll({
                    top: aTag.offsetTop+300,
                    left: 0,
                    behavior: 'smooth'
                })
            };

            const getData = () => {
                const historyParams = history.state.params
                detailInfo(historyParams.id).then(res => {
                    Object.assign(detailinfo, res.data);
                    if (detailinfo) Loading.value = false
                    // console.log(detailinfo.comments)
                    // detailinfo = res.data

                    // 初始化锚点目录
                    let tocItemsNewEl = generateToc(detailinfo.content)
                    tocItems.value = tocItemsNewEl.toc
                    detailinfo.content = tocItemsNewEl.newEl

                })
             };

            const goBack = () => {
                window.history.back();
            }

            onBeforeMount(getData);
            onMounted(() => {

            });

            return {
                detailinfo,
                goBack,
                menuJump,
                Loading,
                tocItems
            }
        }
    }
</script>

<style lang="scss" scoped>
    .container {
        padding-top: 20px;
        padding-bottom: 20px;
        align-items: center;
        background-color: #f8f9fa;
        min-height: 50vh;

        &_base {
            background-color: #fff;
            /*width: 100%;*/
            border: 1px solid rgba(0,0,0,.1);
        }

        &_main {
            margin-right: 10px;
            padding: 30px 20px;
            margin-bottom: 20px;

            &_top {
                position: relative;

                &_title {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 24px;
                }
            }

            &_content {
                padding: 0 10px;
                text-align: left;
                margin-top: 20px;
                border: 1px solid #b9ecf5;
            }
        }

        &_right {
            margin-left: 10px;

            &_userInfo {
                position: relative;
                margin-bottom: 20px;
                padding-bottom: 10px;

                &_bg {
                    background: url(@/assets/img/head_bg.jpg) 50% 50% / cover;
                    height: 120px;
                }

                &_head {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);

                    img {
                        border-radius: 50%;
                        width: 65px;
                        height: 65px;
                    }
                }

                &_nikeName {
                    margin-top: 40px;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 700;
                    letter-spacing: 1px;
                    font-family: serif;
                }

                &_signature {
                    padding: 0 20px;
                    font-family: 'HYQingYunW',serif;
                    font-size: 20px;
                }
            }

            &_directory {
                /*height: 400px;*/
                padding-top: 20px;
                &_top {
                    font-size: 20px;
                }

                #directory {
                    max-height: 680px;
                    overflow-y: auto;
                }


                ul {
                    padding: 0;
                    li {
                        list-style: none;
                        padding: 5px;
                        margin: 5px 0;
                        cursor: pointer;
                    }
                    li:hover {
                        background-color: rgb(225, 239, 245);
                    }
                }
            }
        }
    }
</style>