<template>
    <div class="indexBg">
        <div class="indexBg_info">
            <div class="indexBg_info_left" id="indexBg_info_left">
                <span>——《{{ poetryObject.title }}》</span>
                <p>
                    {{ poetryObject.content }}
                </p>

            </div>
            <div class="indexBg_info_center">
                <span>阅尽好花千万树，愿君记取此一枝</span>
            </div>
        </div>
    </div>
</template>

<script>
import {onMounted, reactive, ref} from "vue";
import {getRandomPoetry} from "@/api/base";

export default {
    name: "TopBg",
    setup() {
        const poetryList = reactive([
            {
                source: '繁星·春水',
                content: '“幽兰！未免太寂寞了，不愿意要友伴么？”“我正寻求着呢？但没有别的花，肯开在空谷里。”'
            },
            {
                source: '柳永词集-木兰花（其二）',
                content: '心娘自小能歌舞，举意动容皆济楚。解教天上念奴羞，不怕掌中飞燕妒。玲珑绣扇花藏语，婉转香裀云衬步。王孙若拟赠千金，只在画楼东畔住。'
            }
        ])

        const poetryObject = ref('')

        function toggleTransparency() {
            const div = document.getElementById('indexBg_info_left');
            // 设置为透明
            div.classList.add('transparent');

            //以下设置请求到资源后移除transparent类，
            // 通过后面的setInterval每30秒执行一次周期，
            // 数据随机性由后端提供，前端数据采用object格式，每次请求只返回一个数据对象

            // 设置两秒后的回调函数来移除transparent类
                setTimeout(() => {
                    getRandomPoetry().then(res => {
                        poetryObject.value = res.data
                        div.classList.remove('transparent');
                    })

                }, 2000); // 2000毫秒后执行





        }

        onMounted(() => {
            // getRandomPoetry().then(res => console.log(res.data))
            getRandomPoetry().then(res => {
                poetryObject.value = res.data
            })
            // 顶部背景左侧诗词css变化
            setInterval(toggleTransparency, 40000); // 60000毫秒 = 1分钟
        })

        return {
            poetryList,
            poetryObject
        }
    }
}
</script>

<style scoped lang="scss">

.indexBg {
  width: 100%;
  height: 384px;
  background: url("@/assets/img/index_bg.jpg") 50% 50% / cover;

  &_info {
    position: relative;
    height: 100%;

    &:after {
      height: 2%;
      bottom: 0;
      background: linear-gradient(0deg, #f8f9fa, transparent);
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
    }

    &_left {
      color: black;
      position: absolute;
      top: 20%;
      left: 2%;
      height: 75%;
      font-family: HYQingYunW, serif;
      font-size: 24px;
      cursor: default;
      display: flex;
      transition: opacity 1s ease-in-out;;
      opacity: 1;

      p {
        writing-mode: vertical-rl;
        text-orientation: upright;
        text-align: left;
        margin-left: 0;

      }
      span {
        writing-mode: vertical-rl;
        text-orientation: upright;
        text-align: right;
      }
    }
    .transparent {
      opacity: 0;
    }

    &_center {
      color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%);
      font-size: 36px;
      font-family: HYQingYunW, serif;
    }
  }

}

</style>