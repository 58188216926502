<template>
    <div>
        <el-affix>
            <TopView></TopView>
        </el-affix>
        <TopBg></TopBg>
        <div class="lineTimeBase">
            <div class="lineTimeBase_lineTitle">
                <span>站点成长日志</span>
            </div>
            <div class="lineTimeBase_lineTime">

                <el-timeline>
                    <el-timeline-item center timestamp="2018/4/12" placement="bottom">
                        <el-card>
                            <h4>Update Github template</h4>
                            <p>Tom committed 2018/4/12 20:46</p>
                        </el-card>
                    </el-timeline-item>
                    <el-timeline-item center timestamp="2018/4/3" placement="bottom">
                        <el-card>
                            <h4>Update Github template</h4>
                            <p>Tom committed 2018/4/3 20:46</p>
                        </el-card>
                    </el-timeline-item>
                    <el-timeline-item center timestamp="2018/4/2" placement="bottom">
                        <el-card>
                            <h4>Update Github template</h4>
                            <p>Tom committed 2018/4/2 20:46</p>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </div>
        <FooterView></FooterView>
    </div>
</template>

<script>
import TopBg from "@/components/TopBg.vue";
import TopView from "@/views/TopView.vue";
import FooterView from "@/views/FooterView.vue";

export default {
    name: "TimeLineView",
    components: {FooterView, TopView, TopBg}
}
</script>

<style lang="scss" scoped>
    .lineTimeBase {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      text-align: center;
        &_lineTitle {
            height: 50px;
            margin: 10px 0;
            line-height: 50px;
        }
        &_lineTime {
            width: 650px;
        }
    }

</style>