<template>
  <div class="list_main">
      <el-space wrap size="large" style="width: 80%;justify-content: center;">
          <div class="list_main_box" v-loading="loading" v-for="item in info" :key="item.id" @click="prompt(item.id)">
            <img :src="item.avatar" alt="#" :id="item.id">

            <div class="list_main_box_title" >
                <div class="title">{{ item.title }}</div>
                <span>{{ item.author.username }}</span>
                <p>{{ item.subtitle }}</p>
            </div>
            <div class="list_main_box_topInfo">
                <div style="margin: 10px 0 0 20px">{{ item.category.name }}</div>
                <div style="margin: 10px 20px 0 0;display: flex">
                    <el-icon style="margin: 1px 3px 0 0"><View /></el-icon>
                    <div>13</div>
                </div>
            </div>
          </div>
      </el-space>
  </div>
</template>

<script>

import {computed, onMounted, reactive, ref, watch} from "vue";
import {setStyleColor} from "@/utils/listMainColor";
import {useRouter} from "vue-router";
import store from "@/store";
import {listInfo} from "@/api/base";

export default {
    name: "TestList",
    setup() {
        const imgUrls = reactive({
            imgUrl: 'http://127.0.0.1:8000/media/avatar/20240327/weread_image_3945775924168.jpeg',
        })

        const router = useRouter()
        const info = ref('');
        const paginator = reactive({
              search: null,
              page: 1,
              size: 2,
              total: null,
        });
        const loading = ref(true)

        const search = computed(() => store.getters.getSearch)

        function setListMianColors(info) {
            info.forEach(item => {
                setStyleColor(item.id)
            })
        }

        const getDate = (newPage) => {
              if(newPage) paginator.page = newPage

              listInfo(paginator).then(res => {
                  info.value = res.data.results
                  if (info.value) loading.value = false
                  paginator.total = res.count

                  setListMianColors(info.value)
              })
        };
        watch(search, (val) => {
            paginator.search = val
            getDate()
        }, { immediate: true })

        const prompt = (id) => {
            const params = {id: id};
            router.push({
                name: 'detail',
                state: {
                    params
                }
            })
        }



        onMounted(() => {
            getDate()

        })

        return {
            imgUrls,
            info,
            prompt,
            loading
        }
    }
}
</script>

<style lang="scss" scoped>
  .list_main {
    position: relative;
    //width: 100%;
    //margin-bottom: 20px;

    &_box {
      width: 200px;
      height: 250px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
      &_topInfo {
        position: absolute;
        top: 0;
        width: 100%;
        //height: 20px;
        //margin: 0 10px;
        font-size: 13px;
        display: flex;
        justify-content: space-between;
      }

      &_title {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 20%;
        //color: black;
        border-radius: 0 0 10px 10px;
        transition: height 0.3s ease;
        overflow: hidden;

        .title {
          margin: 10px 10px 20px 10px;
        }
        span {
          font-size: 12px;
          //margin: 0 0 20px 0;
        }
        p {
          font-size: 12px;
          margin: 10px 20px 0 20px;
        }
      }
    }
  }
</style>