<template>
    <SakuraLoad></SakuraLoad>
    <router-view v-if="isRouterAlive"/>
  <!-- <div style="position: fixed;width: 100%;height: 100%; left:0; top: 0;">
      <div style="margin: 0;border: 0;top: 0;left: 0;width: 100%;height:100%;
      background-size:cover;background-position:top center;
      background-image: url('./index_bg.jpg');background-repeat:no-repeat;"></div>
    </div> -->
</template>
<script>
import SakuraLoad from "@/components/SakuraLoad.vue";
import {nextTick, provide, ref} from "vue";

export default {
    components: {
        SakuraLoad
    },
    setup() {
        // 局部组件刷新
        const isRouterAlive = ref(true);
        const reload = () => {
            isRouterAlive.value = false;
            nextTick(() => {
                isRouterAlive.value = true;
            });
        };
        provide("reload", reload);

        return {
            isRouterAlive,
        };
    }
}
</script>

<style>
@font-face {
    font-family: HYQingYunW;
    src: url('./assets/HYQingYunW.ttf');
    font-weight: normal;
    font-style: normal;
}

#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    position: relative;
    margin: -8px;
}

nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}
</style>