<template>
    <div>
        <el-affix>
            <TopView></TopView>
        </el-affix>
        <TopBg></TopBg>
        <div class="baseClassify">
            <div class="baseBox" v-for="i in 3" :key="i">
                <div class="baseBox_img">
                    <img src="@/assets/img/head.jpg">
                    <img src="@/assets/img/水下的树.jpg">
                    <img src="@/assets/img/这是我的梅树.png">
                </div>
                <div class="baseBox_title">Django</div>
            </div>
        </div>
        <FooterView></FooterView>
    </div>
</template>

<script>
import TopView from "@/views/TopView.vue";
import TopBg from "@/components/TopBg.vue";
import FooterView from "@/views/FooterView.vue";

export default {
    name: "ClassifyView",
    components: {FooterView, TopBg, TopView},
    setup() {

    }
}
</script>

<style lang="scss" scoped>
.baseClassify {
  padding: 20px 30px;
  min-height: 450px;
    display: flex;

  .baseBox {
    width: 150px;
    height: 200px;
      margin: 0 20px;

    &_img {
      width: 100%;
      height: 100%;
      position: relative;

      img:nth-child(1) {
        width: 80%;
        height: 90%;
        position: absolute;
        bottom: 0;
        left: 0;
        box-shadow: 10px 5px 10px 5px rgba(0, 0, 0, 0.5);
      }

      img:nth-child(2) {
        width: 80%;
        height: 90%;
        position: absolute;
        bottom: 0;
        right: 0;
        box-shadow: 10px 5px 10px 5px rgba(0, 0, 0, 0.5);
      }

      img:nth-child(3) {
        width: 70%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        object-fit: cover;
        box-shadow: 10px 0 10px 5px rgba(0, 0, 0, 0.5);
      }
    }
      &_title {
          margin: 15px 0;
          font-family: HYQingYunW,serif;
          font-size: 24px;
      }

  }

}

</style>