// 获取元素主题色
export function getMainColor(imgEl){
    let blockSize = 5, // only visit every 5 pixels
        defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
        canvas = document.createElement('canvas'),
        context = canvas.getContext && canvas.getContext('2d'),
        data, width, height,
        i = -4,
        length,
        rgb = {r:0,g:0,b:0},
        count = 0

    if (!context) {
        return defaultRGB;
    }
    height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
    width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
    context.drawImage(imgEl, 0, 0);
    try {
        data = context.getImageData(0, 0, width, height);
        // console.log(data)
    } catch(e) {
        console.log(e)
        // alert('security error, img on diff domain');
        return defaultRGB;
    }
    length = data.data.length;
    while ( (i += blockSize * 4) < length ) {
        ++count;
        rgb.r += data.data[i];
        rgb.g += data.data[i+1];
        rgb.b += data.data[i+2];
    }
    // ~~ used to floor values
    rgb.r = ~~(rgb.r/count);
    rgb.g = ~~(rgb.g/count);
    rgb.b = ~~(rgb.b/count);
    return 'rgb(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ')';
}

// 获取元素并设置主题色
export function setStyleColor(idEl) {
    let imgEl = document.getElementById(`${idEl}`);
    let mainColor;
    try {
        imgEl.crossOrigin = 'anonymous'
    }catch (e) {
        console.log('list元素还未创建！')
        return null
    }

    imgEl.addEventListener('load', () => {
        if(imgEl.complete) {
            mainColor = getMainColor(imgEl)
            let boxEl = imgEl.parentElement
            let titleEl = imgEl.nextElementSibling
            let titleElFirstChild = titleEl.firstElementChild

            boxEl.addEventListener('mouseenter', () => {
                titleEl.style.cssText = 'position: absolute;\n' +
                    '        bottom: 0;\n' +
                    '        width: 100%;\n' +
                    '        height: 100%;\n' +
                    '        border-radius: 0 0 10px 10px;\n' +
                    `        background: linear-gradient(to bottom, rgba(255,0,0,0) 0, ${mainColor} 50%, ${mainColor} 100%) ;`
                titleElFirstChild.style.cssText = 'margin: 50% 10px 10px 10px;'
            })
            boxEl.addEventListener('mouseleave', () => {
                titleEl.style.cssText = 'position: absolute;\n' +
                    '        bottom: 0;\n' +
                    '        width: 100%;\n' +
                    '        height: 20%;\n' +
                    // '        color: white;\n' +
                    '        border-radius: 0 0 10px 10px;\n' +
                    `        background: ${mainColor};\n` +
                    '        transition: height 0.3s ease;'
                titleElFirstChild.style.cssText = 'margin: 10px 10px 20px 10px;'
            })
            titleEl.style.cssText = 'background-color' + ':' + '' + mainColor + ';'
        }
    })
}