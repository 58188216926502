<template>
    <div class="discuss">
        <div class="discuss_base">
            <div v-for="item in comment" :key="item.id" class="discuss_base_list">
                <el-row>
                    <el-col :span="1">
                        <div class="discuss_base_list_avatar">
                            <el-avatar src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"/>
                        </div>
                    </el-col>
                    <el-col :span="21">
                        <div class="discuss_base_list_mainContent">
                            <div class="discuss_base_list_mainContent_content">
                                <div v-if="item.author">{{ item.author.username }}</div>
                                <div v-else>{{ item.nikeName }}</div>
                                <div v-if="item.parent" class="discuss_base_list_mainContent_content_replay">
                                    <el-icon :size="18" :color="'skyblue'">
                                        <CaretRight/>
                                    </el-icon>
                                    <span v-if="item.parent.author">{{ item.parent.author.username + '：' }}</span>
                                    <span v-else>{{ item.parent.nikeName + '：' }}</span>
                                </div>
                            </div>
                            {{ item.content }}
                            <div class="discuss_base_list_mainContent_content_createTime">{{
                                formatDate(item.created)
                                }}
                            </div>
                            <div v-if="item.children">
                                <div v-for="childrenItem in childrenFormat(item.children)" :key="childrenItem.id"
                                     class="discuss_base_list">
                                    <el-divider></el-divider>
                                    <el-row>
                                        <el-col :span="1">
                                            <div class="discuss_base_list_avatar">
                                                <el-avatar
                                                        src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"/>
                                            </div>
                                        </el-col>
                                        <el-col :span="21">
                                            <div class="discuss_base_list_mainContent">
                                                <div class="discuss_base_list_mainContent_content">
                                                    <div v-if="childrenItem.author">{{
                                                        childrenItem.author.username
                                                        }}
                                                    </div>
                                                    <div v-else>{{ childrenItem.nikeName }}</div>
                                                    <div v-if="childrenItem.parent && childrenItem.parent.id !== item.id"
                                                         class="discuss_base_list_mainContent_content_replay">
                                                        <el-icon :size="15" :color="'skyblue'">
                                                            <CaretRight/>
                                                        </el-icon>
                                                        <span v-if="childrenItem.parent.author ">{{
                                                            childrenItem.parent.author.username + '：'
                                                            }}</span>
                                                        <span v-else>{{ childrenItem.parent.nikeName + '：' }}</span>
                                                    </div>
                                                </div>
                                                {{ childrenItem.content }}
                                                <div class="discuss_base_list_mainContent_content_createTime">
                                                    {{ formatDate(childrenItem.created) }}
                                                </div>
                                            </div>
                                        </el-col>
                                        <el-col :span="1">
                                            <el-button size="small" plain @click="openDialog(childrenItem)">
                                                回复
                                              </el-button>
                                        </el-col>
                                    </el-row>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="1">
                        <el-button size="small" plain @click="openDialog(item)">
                            回复
                          </el-button>
                    </el-col>
                </el-row>
                <el-divider></el-divider>
            </div>
        </div>
        <el-dialog
                v-model="dialog.dialogVisible"
                :title="dialog.title"
                width="600"
                :before-close="handleClose"
                align-center
                center
        >
            <CommentView :article_id="props.article_id" :parent_id="dialog.parent_id"></CommentView>
        </el-dialog>
    </div>
</template>

<script setup>
import {defineProps, reactive, } from "vue";
import {childrenFormat, formatDate, serializer} from "@/utils/utils";
import CommentView from "@/components/CommentView.vue";

const props = defineProps({
    article_comment: {
        type: Array,
        default: () => []
    },
    article_id: {
        type: Number,
        required: true
    },
});

const comment = reactive(serializer(props.article_comment));

const dialog = reactive({
    dialogVisible: false,
    title: '',
    parent_id: null
})

const handleClose = () => {
    dialog.dialogVisible = false
}

function openDialog(data) {
    dialog.dialogVisible = true
    if(data.id) dialog.parent_id = data.id
    if(data.author) {
        dialog.title = '回复 ' + data.author.username
    }else if(data.nikeName) {
        dialog.title = '回复 ' + data.nikeName
    }
}

</script>

<style lang="scss" scoped>
.discuss {
  //background-color: #fff;
  ////border: 1px solid rgba(0, 0, 0, .1);
  //border-top: none;
  //border-right: 1px solid rgba(0, 0, 0, .1);
  //border-left: 1px solid rgba(0, 0, 0, .1);
  //border-bottom: 1px solid rgba(0, 0, 0, .1);
  //margin: 0 10px 0 0;
  //padding: 0 20px 30px 20px;
  text-align: left;

  &_base {
    //border: 1px solid #b9ecf5;
    //border-top: none;
    //padding: 10px;

    &_list {
      //display: flex;
      //margin-bottom: 10px;
      width: 100%;

      &_avatar {
        width: 50px;
        height: 50px;
      }

      &_mainContent {
        margin-left: 10px;

        &_content {
          margin: 0 0 0 5px;
          display: flex;

          &_replay {
            //background-color: gainsboro;
            //font-size: 12px;
          }

          &_createTime {
            font-size: 12px;
            color: gray;
          }
        }
      }
    }
  }


}
</style>