<template>
    <div class="commentView">
        <div class="commentView_base">
            <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="auto" label-position="top">
                <el-row>
                    <el-col :span="11">
                        <el-form-item label="昵称" prop="nikeName">
                            <el-input v-model="ruleForm.nikeName" placeholder="游客发言昵称"/>
                        </el-form-item>
                    </el-col>
<!--                    <el-col :span="2"></el-col>-->
<!--                    <el-col :span="11">-->
<!--                        <el-form-item label="邮箱" prop="email">-->
<!--                            <el-input v-model="ruleForm.email" placeholder="游客评论可输入qq邮箱显示头像(选填)"/>-->
<!--                        </el-form-item>-->
<!--                    </el-col>-->
                    <el-col :span="24">
                        <el-form-item label="评论" prop="content">
                            <el-input
                                    v-model="ruleForm.content"
                                    :autosize="{ minRows: 4, maxRows: 6 }"
                                    type="textarea"
                                    placeholder="好言难劝该死的鬼"
                            />
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="resetForm(ruleFormRef)">清空</el-button>
                            <el-button type="default" @click="submitForm(ruleFormRef)">提交</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>

    </div>
</template>

<script setup>
import {reactive, ref, defineProps} from 'vue';
import {ElMessage} from "element-plus";
import {commentInfo} from "@/api/base";
import { inject } from "vue";


const props = defineProps({
    article_id: {
        // type: Number,
        required: true
    },
    parent_id: {
        type: String,
        default: () => null
    }
});

const ruleFormRef = ref()
const ruleForm = reactive({
    nikeName: '',
    email: '',
    content: '',
    article_id: '',
    parent_id: ''
})
const rules = reactive({
    nikeName: [
        {required: true, message: '请输入您的昵称', trigger: 'blur'},
        {max: 5, message: '昵称长度至多为5个字', trigger: 'blur'},
    ],
    content: [
        {required: true, message: '未输入任何评论', trigger: 'blur'},
    ]
})

const resetForm = (formEl) => {
    if (!formEl) return
    formEl.resetFields()
}
const refresh = inject("reload");


const submitForm = async (formEl) => {
    if (!formEl) return
    await formEl.validate((valid) => {
        if (valid) {
            ruleForm.article_id = props.article_id
            ruleForm.parent_id = props.parent_id
            commentInfo(ruleForm).then(res => {
                console.log(res)
                if(res.status === 200 || res.status === 201) {
                    ElMessage({
                        message: '评论成功',
                        type: 'success',
                    })
                    refresh()
                }
            })

        } else {
            ElMessage({
                message: '您未进行评论',
                type: 'error',
            })
        }
    })
}

</script>

<style lang="scss" scoped>
.commentView {
  //background-color: #fff;
  //border-bottom: none;
  //border-top: 1px solid rgba(0, 0, 0, .1);
  //border-left: 1px solid rgba(0, 0, 0, .1);
  //border-right: 1px solid rgba(0, 0, 0, .1);
  //margin: 15px 10px 0 0;
  //padding: 30px 20px 0 20px;
  text-align: left;

  &_base {
    //border: 1px solid #b9ecf5;
    //border-bottom: none;
    //padding: 10px;

    &_discuss {

      &_list {
        //display: flex;
        //margin-bottom: 10px;
        width: 100%;

        &_avatar {
          width: 50px;
          height: 50px;
        }

        &_content {
          margin: 0 0 0 5px;

          &_createTime {
            font-size: 12px;
            color: gray;
          }
        }
      }
    }
  }

  ::v-deep .el-textarea__inner {
    border: 1px solid #b9ecf5;
    border-radius: unset;
  }
}
</style>