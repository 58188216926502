<template>
    <div class="home">

        <el-affix>
            <TopView></TopView>
        </el-affix>
        <TopBg></TopBg>
        <div class="Container">
<!--            <ListView></ListView>-->
            <TestList></TestList>
        </div>
        <el-backtop :right="100" :bottom="100"/>
        <FooterView></FooterView>
    </div>
</template>

<script>
// @ is an alias to /src
import TopView from './TopView.vue';
// import ListView from './ListView.vue';
import TopBg from "@/components/TopBg";
import FooterView from "@/views/FooterView.vue";
import TestList from "@/views/TestList.vue";


export default {
    name: 'HomeView',
    components: {
        TestList,
        TopView,
        // ListView,
        TopBg,
        FooterView
    },

}
</script>

<style lang="scss" scoped>
.Container {
  //margin-top: 20px;
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  //align-items: center;
  min-height: 500px;

  //后加的
  margin: 20px 20px 0 20px;
}

</style>
