<template>
    <el-affix>
        <TopView></TopView>
    </el-affix>
  <div class="aboutBase">
      <div class="aboutBase_Base">
          <div class="aboutBase_Base_bg"></div>
      </div>
      <div class="aboutBase_main">
          <el-row :gutter="15">
            <el-col :span="6">
                <div class="aboutBase_leftInfo box">
                      <div class="aboutBase_leftInfo_topBg"></div>
                      <div class="aboutBase_leftInfo_head">
                        <img src="https://q1.qlogo.cn/g?b=qq&nk=2715355163&s=100" alt="#">
                        <div class="nikeName">祈年</div>
                        <div class="qqPhone">2715355163</div>
                      </div>
                    <div class="aboutBase_leftInfo_baseInfo">
                        <div class="aboutBase_leftInfo_baseInfo_span">
                            <span>男</span>
                            <span>120岁</span>
                            <span>天秤座</span>
                        </div>
                        <div>个签：能文能武</div>
                        <div>邮箱：liyxxlz@163.com</div>
                    </div>
                     <div class="aboutBase_leftInfo_filing">
                         <div class="days">
                             本站已运行：{{ days }}
                         </div>
                         <div>
                            <a href="https://beian.miit.gov.cn/">渝ICP备2020010248号-1</a>
                        </div>
                        <div>
                            <a target="_blank" href="http://www.beian.gov.cn/">
                                <img src="@/assets/img/备案图标.png"/>
                                渝公网安备 50023502000372号
                            </a>
                        </div>
                     </div>
                  </div>
            </el-col>
            <el-col :span="18">
                <div class="aboutBase_rightInfo box" >
                    <div class="aboutBase_rightInfo_title box">本站概况</div>
                    <div class="aboutBase_rightInfo_content box">
                        <p>本站基于Django、Vue、MySQL独立开发，部署在阿里云服务器上。</p>
                        <p>本站走走停停经历了好几个版本，几度放弃，但最终许是我的执念作祟，在犹豫之后毅然决定一直维护下去。</p>
                        <p>19年我刚上大一，学了python和网页设计，在网上冲浪的时间发现大量优质的个人网站，于是心生向往，并开始自己尝试搭建网站。
                            好在这个过程并非一帆风顺，让我有了宝贵的开发经验，并借此慢慢的走上了程序猿的道路。</p>
                        <p>祈年安安第一版是以php+html+css+js搭建而成，借助别人已经写好的网站搭建，这个过程并不复杂。我本以为我会慢慢的在网站上发表自己的作品文章或者记录生活什么的，但总感觉什么地方歪了。
                            没错，虽然网站已经搭建成功，但我并不满足直接抄别人作业。好像自己没有参与感就不舒服一样。</p>
                        <p>于是有了第二版祈年安安，以springboot+html+css+js搭建而成。
                        这版是自己完全设计编程的，各种功能还算齐全，但由于网页设计方面在自己眼里实在太丑，几度不愿再碰。搁置了一段时间后狠下心又重新式参考别人的设计。</p>
                        <p>第三版祈年安安以django框架和其模板语法搭建而成，但未做成前后端分离，维护起来麻烦，于是又搁置一段时间。这时候由于学业繁忙已经不想再碰自己的个人网站了。
                        但有些东西，在自己心里，说不清道不明的。在学业和工作稳定下来后，虽说繁忙，闲暇的时间也慢慢捡起以前的东西缝缝补补。</p>
                        <p>第四版祈年安安采用django+vue搭建。部分功能实现依赖于第三方库，实在没那么多精力全部自己写。好在这版网页设计方面，自己看来不至于太丑，索性就这样慢慢走吧。</p>
                    </div>
                </div>
            </el-col>
          </el-row>
      </div>

  </div>

</template>

<script>
import TopView from "@/views/TopView.vue";
import {measureTime} from "@/utils/utils";

export default {
    name: "AboutView",
    components: {TopView},
    setup() {
        const days = measureTime('2020-10-01')
        return {
            days
        }
    }
}
</script>

<style lang="scss" scoped>
  .aboutBase {
      width: 100%;
      &_Base {
          position: fixed;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;

          &_bg {
            margin: 0;
            border: 0;
            top: 0;
            left: 0;
            width: 100%;
            height:100%;
            background: url("@/assets/img/index_bg.jpg") no-repeat top center / cover;

          }
      }

      &_main {
        position: relative;
        width: 70%;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);


      }

      &_leftInfo {
        height: 560px;
        padding: 0;
        position: sticky;
        top: 80px;

        &_topBg {
          background-image: url("@/assets/img/wen.jpg");
          object-fit: cover;
          background-position-x: center;
          background-position-y: center;
          background-size: cover;
          min-height: 150px;
          width: 100%;
          overflow: hidden;
          border-top-right-radius: 6px;
          border-top-left-radius: 6px;
        }
        &_head {
          margin-left: 50px;
          display: flex;

          img {
            transform: translateX(-50%) translateY(-50%);
            width: 68px;
            height: 68px;
            border-radius: 50%;
            vertical-align: middle;
            border-style: none;
          }
          .nikeName {
            margin-top: -22px;
            margin-left: -25px;
            color: white;
          }
          .qqPhone {
            margin-left: -30px;
            font-size: 13px;
          }

        }
        &_baseInfo {
          margin: -25px 20px 0 20px;
          display: flex;
          flex-direction: column;
          text-align: left;
          position: relative;

          div {
            margin-bottom: 5px;
          }
          &_span {
            display: flex;
            span {
              margin-right: 15px;
            }
          }
        }
        &_filing {
            position: absolute;
            bottom: 10px;
            width: 100%;
            .days {
              margin: 10px;
              font-size: 14px;
            }
            a {
              text-decoration: none;
            }
          }
      }

      &_rightInfo {
        height: 560px;
        //background-color: red;
        padding: 1.875rem;
        box-sizing: border-box;


        &_title {
          width: 120px;
          padding: 2px 10px;
          margin: 0 auto;
        }
        &_content {
          margin-top: 20px;
          height: 90%;
          padding: 20px;
          box-sizing: border-box;
          text-align: left;
          font-size: 14px;
        }
      }

      .box {
        border-radius: 0.937rem;
        box-shadow: 0 24px 40px rgb(134 151 168 / 25%);
        transition: box-shadow .3s ease-in-out;
        background: rgba(255, 255, 255, 0.5);
      }
  }
</style>